import { ShowBenefits } from "interfaces/benefitInterface"
import { CreatedCommunity } from "interfaces/communityInterface"
import { SymplaEvent } from "interfaces/symplaEvent"
import { DisplayTokens } from "interfaces/tokenInterface"
import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from "react"
import { cookies } from "utility/cookies"

import {
  CreatingCommunity,
  CreationVisibility,
  SecondFormCreateCommunityInputs,
  ShowCollections
} from "../../views/my-collections/collection-types"
import { ProductOfferStatus, DeleteModalInfos, ProductUtilityTokenModal } from "interfaces/productInterface"
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from "react-query"
import { NetworkEnum } from "interfaces/sharedEnums"
import { getCompanyByJWT, getDefaultWalletBalance } from "services/axiosCompanyRequests"
import { WalletBalance } from "interfaces/walletInterface"
import { CreatedCompany } from "interfaces/companyInterface"
import { currencyNameByNetwork } from "views/settings/company-settings/utils/currencyNameByNetwork"
import { currencyLogoByNetwork } from "views/settings/company-settings/utils/currencyLogoByNetwork"
import { ContractedPlansEnum } from "interfaces/sharedEnums/contractedPlans.enum"
import { getAdminWelcomeData } from "services/axiosWelcomeDataRequests"
import {
  MyWayCurrencyEnum,
  getCheckingAccountBalance,
  getWithdrawalHistory
} from "../../services/axiosCheckingAccountsRequests"
import { AxiosResponse } from "axios"

export interface IContext {
  userData: UserData
  setUserData: any
  iscreatedRoyalties: boolean
  setIscreatedRoyalties: (string: boolean) => void
  isCreatedBenefit: boolean
  setIsCreatedBenefit: (string: boolean) => void
  showBenefits: ShowBenefits
  setShowBenefits: (string: ShowBenefits) => void
  productOfferStatus: ProductOfferStatus
  deleteModal: DeleteModalInfos
  setDeleteModal: Dispatch<SetStateAction<DeleteModalInfos>>
  productUtilityTokens: ProductUtilityTokenModal
  setProductUtilityTokens: Dispatch<SetStateAction<ProductUtilityTokenModal>>
  setProductOfferStatus: (string: ProductOfferStatus) => void
  showCollections: ShowCollections
  setShowCollections: (string: ShowCollections) => void
  showTokens: DisplayTokens
  setShowTokens: (string: DisplayTokens) => void
  showCommunities: "display" | "to-manage" | "edit" | "create"
  setShowCommunities: (string: "display" | "to-manage" | "edit" | "create") => void
  setEventsView: (string: "initial" | "tokenize") => void
  eventsView: "initial" | "tokenize"
  collectionCreationVisibility: CreationVisibility
  setCollectionCreationVisibility: (string: CreationVisibility) => void
  creatingCommunity: CreatingCommunity
  setCreatingCommunity(string)
  secondFormaData: SecondFormCreateCommunityInputs
  setSecondFormData: (string: SecondFormCreateCommunityInputs) => void
  communityData: any
  setCommunityData: (string: any) => void
  redirectEndpoint: string
  setRedirectEndpoint: (endpoint: string) => void
  eventToTokenize: SymplaEvent | undefined
  setEventToTokenize: any
  integrationIdToTokenize: string
  setIntegrationIdToTokenize: any
  tokenType: CreationVisibility
  setTokenType: (string: CreationVisibility) => void
  defaultWalletBalanceData: WalletBalance
  defaultWalletBalanceLoading: boolean
  refetchDefaultWalletBalance: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<WalletBalance, unknown>>
  networkToFetchBalance: NetworkEnum
  setNetworkToFetchBalance: Dispatch<SetStateAction<NetworkEnum>>
  initialNetwork: NetworkEnum
  gnosisNetwork: NetworkEnum
  chilizNetwork: NetworkEnum
  baseNetwork: NetworkEnum
  manageCollectionWhitelist: any
  setManageCollectionWhitelist: any
  adminWelcomeData: any
  isAdminWelcomeDataLoading: boolean
  refetchWelcomeData: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<any, unknown>>
  isChangePasswordModalOpen: boolean
  setIsChangePasswordModalOpen: Dispatch<SetStateAction<boolean>>
  companyConfig: CreatedCompany
  networksData: any
}
export interface UserData {
  contractedPlans: ContractedPlansEnum[]
  accessToken: string
  email: string
  id: string
  role: string
  status: string
  communities: Community[]
  companies: Company[]
  secsToExpire: number
  firstLogin?: boolean
  lastAccess?: Date
}

interface Company {
  companyId: string
  role: string
}

export interface Community {
  communityId: string
  role: string
}

interface ContextProviderProps {
  children: ReactNode
}

const Context = createContext<IContext>(null)

export const ContextProvider: FC<ContextProviderProps> = ({ children }) => {
  const [iscreatedRoyalties, setIscreatedRoyalties] = useState(false)
  const [isCreatedBenefit, setIsCreatedBenefit] = useState(false)
  const [redirectEndpoint, setRedirectEndpoint] = useState("")
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false)

  const [showBenefits, setShowBenefits] = useState<"Home" | "Creating" | "Editing">("Home")
  const [productOfferStatus, setProductOfferStatus] = useState<ProductOfferStatus>("Home")
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    productInfos: { name: "", displayImageUrl: "", productId: "" }
  })

  const [productUtilityTokens, setProductUtilityTokens] = useState({
    isOpen: false,
    utilityTokens: []
  })
  const [showCollections, setShowCollections] = useState<ShowCollections>("display")
  const [showCommunities, setShowCommunities] = useState<"display" | "to-manage" | "edit" | "create">("display")
  const [showTokens, setShowTokens] = useState<DisplayTokens>("display")
  const [collectionCreationVisibility, setCollectionCreationVisibility] = useState<CreationVisibility>("typeSelection")
  const [creatingCommunity, setCreatingCommunity] = useState<CreatingCommunity>("config")
  const [communityData, setCommunityData] = useState({})
  const [eventsView, setEventsView] = useState<"initial" | "tokenize">("initial")
  const [eventToTokenize, setEventToTokenize] = useState<SymplaEvent | undefined>()
  const [integrationIdToTokenize, setIntegrationIdToTokenize] = useState<string | undefined>()
  const [tokenType, setTokenType] = useState<CreationVisibility | undefined>("typeSelection")
  const [manageCollectionWhitelist, setManageCollectionWhitelist] = useState({
    selectedMember: null,
    activeModal: null,
    action: null
  })

  const initialNetwork =
    process.env.REACT_APP_ENVIRONMENT === "production" ? NetworkEnum.POLYGON_MAINNET : NetworkEnum.POLYGON_AMOY
  const gnosisNetwork =
    process.env.REACT_APP_ENVIRONMENT === "production" ? NetworkEnum.GNOSIS_MAINNET : NetworkEnum.GNOSIS_TESTNET
  const chilizNetwork =
    process.env.REACT_APP_ENVIRONMENT === "production" ? NetworkEnum.CHILIZ_MAINNET : NetworkEnum.CHILIZ_TESTNET
  const baseNetwork =
    process.env.REACT_APP_ENVIRONMENT === "production" ? NetworkEnum.BASE_MAINNET : NetworkEnum.BASE_SEPOLIA

  const networksData = [
    {
      network: initialNetwork,
      networkName: currencyNameByNetwork[initialNetwork],
      networkLogo: currencyLogoByNetwork[initialNetwork],
      title: "Matic (Polygon)"
    },
    {
      network: gnosisNetwork,
      networkName: currencyNameByNetwork[gnosisNetwork],
      networkLogo: currencyLogoByNetwork[gnosisNetwork],
      title: "xDAI (Gnosis)"
    },
    {
      network: chilizNetwork,
      networkName: currencyNameByNetwork[chilizNetwork],
      networkLogo: currencyLogoByNetwork[chilizNetwork],
      title: "CHZ (Chiliz)"
    },
    {
      network: baseNetwork,
      networkName: currencyNameByNetwork[baseNetwork],
      networkLogo: currencyLogoByNetwork[baseNetwork],
      title: "Base (Base)"
    }
  ]

  const [networkToFetchBalance, setNetworkToFetchBalance] = useState<NetworkEnum>(initialNetwork)

  const [secondFormaData, setSecondFormData] = useState<SecondFormCreateCommunityInputs>({
    pageTitle: "",
    link: "",
    iconUrl: "",
    descriptionTheme: "",
    logoHeaderUrl: "",
    colorBackgroundLayout: "",
    colorText: "",
    textColorHouver: "",
    colorButton: "",
    bodyBackgroundColor: "",
    bodyBackgroundImage: "",
    bodyTextColor: "",
    bodyTextHouverColor: "",
    bodyHighlightColor: "",
    bodyButtonColor: "",
    bodyButtonTextColor: "",
    bodyLinkColor: "",
    footerLink: "",
    footerbackgroundColor: "",
    footerTextColor: "",
    footerTextHouverColor: "",
    loadingColor: "",
    loadingBackgroundColor: ""
  })

  const data = cookies.get("userData")?.data

  const [userData, setUserData] = useState(data || {})

  const {
    data: defaultWalletBalanceData,
    isFetching: defaultWalletBalanceLoading,
    refetch: refetchDefaultWalletBalance
  } = useQuery(
    ["/users/default-wallet/balance", userData?.accessToken, networkToFetchBalance],
    () => getDefaultWalletBalance(userData?.accessToken, networkToFetchBalance).then((response) => response.data),
    { refetchOnWindowFocus: false, enabled: !!userData?.accessToken, retry: false }
  )

  const { data: companyConfig, isLoading: isCompanyConfigLoading } = useQuery(
    ["/company", userData?.accessToken],
    () => {
      return getCompanyByJWT(userData?.accessToken).then((response) => {
        return response.data?.companies?.[0]
      })
    },
    { refetchOnWindowFocus: false, enabled: !!userData?.accessToken }
  )

  useEffect(() => {
    if (userData?.accessToken) {
      refetchDefaultWalletBalance()
    }
  }, [networkToFetchBalance, userData?.accessToken])

  const {
    data: adminWelcomeData,
    refetch: refetchWelcomeData,
    isLoading: isAdminWelcomeDataLoading
  } = useQuery(
    ["/admin-welcome-data", userData?.accessToken],
    () => {
      return getAdminWelcomeData({ token: userData?.accessToken, companyId: companyConfig?.id }).then(
        (response) => response.data
      )
    },
    { refetchOnWindowFocus: false, enabled: !!userData?.accessToken && !!companyConfig?.id }
  )

  return (
    <Context.Provider
      value={{
        userData,
        setUserData,
        iscreatedRoyalties,
        setIscreatedRoyalties,
        isCreatedBenefit,
        setIsCreatedBenefit,
        showBenefits,
        setShowBenefits,
        productOfferStatus,
        setProductOfferStatus,
        showCollections,
        setShowCollections,
        showTokens,
        setShowTokens,
        showCommunities,
        setShowCommunities,
        collectionCreationVisibility,
        setCollectionCreationVisibility,
        creatingCommunity,
        setCreatingCommunity,
        secondFormaData,
        setSecondFormData,
        communityData,
        setCommunityData,
        eventsView,
        setEventsView,
        setEventToTokenize,
        eventToTokenize,
        integrationIdToTokenize,
        setIntegrationIdToTokenize,
        deleteModal,
        setDeleteModal,
        productUtilityTokens,
        setProductUtilityTokens,
        redirectEndpoint,
        setRedirectEndpoint,
        tokenType,
        setTokenType,
        defaultWalletBalanceData,
        defaultWalletBalanceLoading,
        refetchDefaultWalletBalance,
        networkToFetchBalance,
        setNetworkToFetchBalance,
        initialNetwork,
        gnosisNetwork,
        chilizNetwork,
        baseNetwork,
        manageCollectionWhitelist,
        setManageCollectionWhitelist,
        adminWelcomeData,
        refetchWelcomeData,
        isChangePasswordModalOpen,
        setIsChangePasswordModalOpen,
        networksData,
        isAdminWelcomeDataLoading,
        companyConfig
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useGlobalContext = () => {
  const context = useContext(Context)
  if (!context) {
    throw new Error("useGlobalContext must be used within a StoreProvider")
  }
  return context
}
