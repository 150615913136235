import { useState, useEffect, ReactNode, FC } from "react"
import SelectedCommunityContext from "./selectedCommunityContext"

interface SelectedCommunityProviderProps {
  children: ReactNode
}

export const SelectedCommunityProvider: FC<SelectedCommunityProviderProps> = ({ children }) => {
  const [selectedCommunity, setSelectedCommunity] = useState(() => {
    const savedCommunity = localStorage.getItem("selectedCommunity")
    return savedCommunity !== undefined ? JSON.parse(savedCommunity) : null
  })

  useEffect(() => {
    if (selectedCommunity !== null) {
      localStorage.setItem("selectedCommunity", JSON.stringify(selectedCommunity))
    }
  }, [selectedCommunity])

  return (
    <SelectedCommunityContext.Provider value={{ selectedCommunity, setSelectedCommunity }}>
      {children}
    </SelectedCommunityContext.Provider>
  )
}
